export const size = {
  mobile: 767,
};

export const device = {
  desktop: `(min-width: 1441px)`,
  laptop: `(min-width: 992px) and (max-width: 1440px)`,
  mobile: `(max-width: ${size.mobile}px)`,
  tablet: `(min-width: 768px) and (max-width: 991.98px)`,
};
