import { createGlobalStyle } from 'styled-components';

import { palette } from '../../consts';

export const GlobalStyles = createGlobalStyle`
  body {
    color: ${palette.mineShaft};
    font-family: 'Space Grotesk', 'sans-serif';
    font-size: 1rem; 
    font-weight: 400;
  }
`;
