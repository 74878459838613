import type { FC, ReactNode } from 'react';
import React, { useMemo } from 'react';

import { Container, Item } from './grid.styles';
import type { GridProps } from './grid.styles';

type Props = GridProps & {
  children: NonNullable<ReactNode>;
};

export const Grid: FC<Props> = (props) => {
  const { children, container, ...rest } = props;
  const Component = useMemo(() => (container ? Container : Item), [container]);

  return <Component {...rest}>{children}</Component>;
};
