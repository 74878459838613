import { useQuery as ReactQueryUseQuery } from 'react-query';
import type { QueryFunction, QueryKey } from 'react-query/types/core/types';
import type {
  UseQueryOptions,
  UseQueryResult,
} from 'react-query/types/react/types';

export const useQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  key: TQueryKey,
  queryFn: QueryFunction<TQueryFnData>,
  options?: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    | 'refetchIntervalInBackground'
    | 'refetchOnMount'
    | 'refetchOnWindowFocus'
    | 'queryKey'
  >,
): UseQueryResult<TData, TError> => {
  return ReactQueryUseQuery(key, queryFn, {
    ...options,
    refetchIntervalInBackground: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};
