import type { FC } from 'react';
import React from 'react';

import { getContactInfo$, QUERY_KEY, useQuery } from '../../http';
import { MailIcon, PhoneIcon, PinIcon } from '../../icons';
import { Container, Element } from './contact-info.styles';

export const ContactInfo: FC = () => {
  const { data } = useQuery(QUERY_KEY.ContactInfo, getContactInfo$);

  if (!data) return null;

  const { address, email, phone } = data;

  return (
    <Container>
      <Element>
        <PhoneIcon height={24} width={24} />
        <a href={`tel:${phone}`} rel="noreferrer">
          {phone}
        </a>
      </Element>
      <Element>
        <MailIcon height={24} width={24} />
        <a href={`mailto:${email}`} rel="noreferrer">
          {email}
        </a>
      </Element>
      {address ? (
        <Element>
          <PinIcon height={24} width={24} />
          <span>{address}</span>
        </Element>
      ) : null}
    </Container>
  );
};
