import type { FC } from 'react';
import React, { useCallback } from 'react';
import { generatePath, Link } from 'react-router-dom';

import { PATHS } from '../../../../paths.const';
import {
  getApartments$,
  getPage$,
  PageSlug,
  QUERY_KEY,
  useQuery,
} from '../../http';
import { useTranslation } from '../../translations';
import { Features } from '../Features/features.component';
import { Grid } from '../Grid';
import { PageTitle } from '../PageTitle/page-title.component';
import { Section } from '../Section/section.component';
import { Box, Image, ImageContainer, Title } from './apartments.styles';

export const Apartments: FC = () => {
  const { langCode } = useTranslation();
  const getPageData$ = useCallback(
    () => getPage$(PageSlug.Apartments, langCode),
    [langCode],
  );
  const { data: page } = useQuery(
    [QUERY_KEY.ApartmentsPage, PageSlug.Apartments, langCode],
    getPageData$,
  );
  const getApartmentsData$ = useCallback(
    () => getApartments$(langCode),
    [langCode],
  );
  const { data: apartments } = useQuery(
    [QUERY_KEY.Apartments],
    getApartmentsData$,
  );

  if (!apartments || !page) return null;

  return (
    <Section id="apartments">
      <PageTitle center>{page.title || page.name}</PageTitle>
      <Grid container justifyContent="center">
        {apartments.map((apartment) => {
          const { id, images, name } = apartment;
          const url = generatePath(PATHS.DETAILS, { id });
          const [image] = images;

          return (
            <Grid columns={3} key={id}>
              <Box>
                {images[0] && (
                  <ImageContainer>
                    <Link title={name} to={url}>
                      <Image url={image} />
                    </Link>
                  </ImageContainer>
                )}
                <Title>
                  <Link title={name} to={url}>
                    {name}
                  </Link>
                </Title>
                <Features features={apartment.features} />
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Section>
  );
};
