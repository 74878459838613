import type { FC } from 'react';
import React, { lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { GlobalStyles, Layout, Loadable } from './shared';

const Home = Loadable(lazy(() => import('./Home/home.component')));
const Details = Loadable(lazy(() => import('./Details/details.component')));
const NotFound = Loadable(lazy(() => import('./NotFound/not-found.component')));

export const App: FC = () => {
  return (
    <>
      <GlobalStyles />
      <BrowserRouter>
        <Routes>
          <Route element={<Layout />} path="/">
            <Route element={<Home />} index />
            <Route element={<Details />} path="apartments/:id" />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};
