import styled from 'styled-components';

import { device, palette } from '../../consts';

type BurgerProps = { open: boolean };

export const StyledButton = styled('div')`
  display: none;
  height: 24px;
  position: relative;
  transition-duration: 0.5s;
  width: 24px;
  z-index: 11;

  @media ${device.mobile} {
    display: block;
  }
`;

const Icon = styled('div')<BurgerProps>`
  background-color: ${({ open }) => (open ? 'transparent' : palette.white)};
  height: 3px;
  margin-top: -1.5px;
  position: absolute;
  top: 50%;
  transition-duration: 0.5s;
  width: 12px;

  &:before,
  &:after {
    background-color: ${palette.white};
    content: '';
    height: 3px;
    position: absolute;
    transition-duration: 0.5s;
    width: 12px;
  }

  &:before {
    top: -8px;
  }

  &:after {
    top: 8px;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const IconLeft = styled(Icon)<BurgerProps>`
  left: 0;

  &:before {
    transform: ${({ open }) =>
      open ? 'rotateZ(45deg) scaleX(1.4) translate(1.5px, 1.5px)' : 'unset'};
  }

  &:after {
    transform: ${({ open }) =>
      open ? 'rotateZ(-45deg) scaleX(1.4) translate(1.5px, -1.5px)' : 'unset'};
  }
`;

export const IconRight = styled(Icon)<BurgerProps>`
  left: 12px;

  &:before {
    transform: ${({ open }) =>
      open ? 'rotateZ(-45deg) scaleX(1.4) translate(-1.5px, 1.5px)' : 'unset'};
  }

  &:after {
    transform: ${({ open }) =>
      open ? 'rotateZ(45deg) scaleX(1.4) translate(-1.5px, -1.5px)' : 'unset'};
  }
`;
