import styled from 'styled-components';

import { device } from '../../consts';

type StyledSectionProps = { isDense?: boolean };

export const StyledSection = styled('section')<StyledSectionProps>((props) => ({
  margin: props.isDense ? '16px auto' : '24px auto',
  maxWidth: 1440,
  padding: props.isDense ? '16px 32px' : '24px 32px',
  width: '100%',

  [`@media ${device.mobile}`]: {
    padding: 16,
  },
}));
