import styled from 'styled-components';

import { device } from '../../consts';

export const Wrapper = styled('div')`
  display: flex;
  justify-content: center;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;
