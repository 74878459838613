import type { FC } from 'react';
import React from 'react';

import { Logo } from '../Logo/logo.component';
import { Navigation } from '../Navigation/navigation.component';
import { ExtendedSection, StyledHeader } from './header.styles';

export const Header: FC = () => {
  return (
    <StyledHeader>
      <ExtendedSection isDense>
        <Logo />
        <Navigation />
        {/* <LanguageSwitcher /> */}
      </ExtendedSection>
    </StyledHeader>
  );
};
