import type { FC } from 'react';
import React, { useCallback } from 'react';

import { getPage$, PageSlug, QUERY_KEY, useQuery } from '../../http';
import { useTranslation } from '../../translations';
import { ContactForm } from '../ContactForm/contact-form.component';
import { ContactInfo } from '../ContactInfo/contact-info.component';
import { PageTitle } from '../PageTitle/page-title.component';
import { Section } from '../Section/section.component';
import { Wrapper } from './contact.styles';

export const Contact: FC = () => {
  const { langCode } = useTranslation();
  const getPageData$ = useCallback(
    () => getPage$(PageSlug.Contact, langCode),
    [langCode],
  );
  const { data } = useQuery(
    [QUERY_KEY.ContactPage, PageSlug.Contact, langCode],
    getPageData$,
  );

  return (
    <Section id="contact">
      {data && <PageTitle center>{data.title || data.name}</PageTitle>}
      <Wrapper>
        <ContactInfo />
        <ContactForm />
      </Wrapper>
    </Section>
  );
};
