import styled from 'styled-components';

import { device, palette } from '../../consts';

export const Container = styled(`div`)`
  align-self: start;
  border-right: 1px solid ${palette.biscay};
  margin-right: 48px;
  padding: 8px 48px 8px 0;

  @media ${device.mobile} {
    align-self: center;
    border-right: unset;
    margin-bottom: 24px;
    margin-right: 0;
    padding: 0;
  }
`;

export const Element = styled('div')`
  align-items: center;
  display: flex;
  margin-bottom: 16px;

  > svg {
    color: ${palette.biscay};
    margin-right: 12px;
  }
`;
