import styled from 'styled-components';

export const StyledFullPreview = styled('div')({
  backdropFilter: 'blur(8px)',
  backgroundColor: `rgba(0, 0, 0, 0.7)`,
  bottom: 0,
  left: 0,
  position: 'fixed',
  right: 0,
  top: 0,
});

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'space-between',
  margin: 'auto',
  padding: 48,
  width: '100%',
});

export const Image = styled('div')<{ url: string }>((props) => ({
  backgroundImage: `url(${props.url})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  height: '75%',
}));
