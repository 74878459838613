import i18next from 'i18next';
import 'nprogress/nprogress.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { App, TranslationProvider } from './app';
import './index.css';

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

const queryClient = new QueryClient();

root.render(
  <I18nextProvider i18n={i18next}>
    <QueryClientProvider client={queryClient}>
      <TranslationProvider>
        <App />
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
        />
      </TranslationProvider>
    </QueryClientProvider>
  </I18nextProvider>,
);
