import type { FC } from 'react';
import React from 'react';

import { useTranslation } from '../../translations';
import { ButtonRow, StyledButton, StyledLink } from './button.styles';

type Props = {
  textKey: GenericType.TranslationKey;
  isDisabled?: boolean;
  to?: string;
};

export const Button: FC<Props> = (props) => {
  const { isDisabled, textKey, to } = props;
  const { t } = useTranslation();
  const text = t(textKey);

  return (
    <ButtonRow>
      {to && (
        <StyledLink disabled={isDisabled} to={to}>
          {text}
        </StyledLink>
      )}
      {!to && (
        <StyledButton disabled={isDisabled} type="submit">
          {text}
        </StyledButton>
      )}
    </ButtonRow>
  );
};
