import styled, { keyframes } from 'styled-components';

import { palette } from '../../consts';

const spin = keyframes`
  to {
    -webkit-transform: rotate(360deg);
  }
`;

export const StyledSpinner = styled('div')`
  display: block;
  margin: auto;
  width: 50px;
  height: 50px;
  border: 3px solid ${palette.biscay};
  border-radius: 50%;
  border-top-color: #fff;
  animation: ${spin} 1s ease-in-out infinite;
  -webkit-animation: ${spin} 1s ease-in-out infinite;
`;
