import { send } from '@emailjs/browser';
import { yupResolver } from '@hookform/resolvers/yup';
import type { FC } from 'react';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { useTranslation } from '../../translations';
import { Button } from '../Button/button.component';
import { Error, Field, Form, Input, Textarea } from './contact-form.styles';

const serviceId = process.env.REACT_APP_SERVICE_ID || '';
const templateId = process.env.REACT_APP_TEMPLATE_ID || '';
const userId = process.env.REACT_APP_USER_ID || '';

type Values = {
  email: string;
  message: string;
  name: string;
};

const validationSchema = Yup.object({
  email: Yup.string().email('form.error.email').required('form.error.required'),
  message: Yup.string().required('form.error.required'),
  name: Yup.string().required('form.error.required'),
});

export const ContactForm: FC = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields, isValid },
    reset,
  } = useForm<Values>({
    mode: 'onTouched',
    resolver: yupResolver(validationSchema),
  });
  const [isFetching, setFetching] = useState(false);
  const onSubmit$ = useCallback(
    async (values: Values) => {
      setFetching(true);

      try {
        await send(serviceId, templateId, values, userId);
        const text = t('form.success');

        toast.success(text);
        reset();
      } catch (e) {
        const text = t('form.error.global');

        toast.error(text);
      } finally {
        setFetching(false);
      }
    },
    [reset, t],
  );
  const nameErrorText =
    touchedFields.name && errors.name?.message ? t(errors.name.message) : '';
  const emailErrorText =
    touchedFields.name && errors.email?.message ? t(errors.email.message) : '';
  const messageErrorText =
    touchedFields.name && errors.message?.message
      ? t(errors.message.message)
      : '';

  return (
    <Form onSubmit={handleSubmit(onSubmit$)} noValidate>
      <Field>
        <Input
          {...register('name', { disabled: isFetching })}
          isError={Boolean(touchedFields.name && errors.name)}
          placeholder={t('form.fields.name')}
        />
        {touchedFields.name && errors.name ? (
          <Error>{nameErrorText}</Error>
        ) : null}
      </Field>
      <Field>
        <Input
          {...register('email', { disabled: isFetching })}
          isError={Boolean(touchedFields.email && errors.email)}
          placeholder={t('form.fields.email')}
          type="email"
        />
        {touchedFields.email && errors.email ? (
          <Error>{emailErrorText}</Error>
        ) : null}
      </Field>
      <Field>
        <Textarea
          {...register('message', { disabled: isFetching })}
          isError={Boolean(touchedFields.message && errors.message)}
          placeholder={t('form.fields.message')}
          rows={5}
        />
        {touchedFields.message && errors.message ? (
          <Error>{messageErrorText}</Error>
        ) : null}
      </Field>
      <Button isDisabled={isFetching || !isValid} textKey="form.button" />
    </Form>
  );
};
