import type { FC } from 'react';
import React from 'react';

import { StyledPageTitle } from './page-title.styles';

type Props = {
  children: string;
  center?: boolean;
  dense?: boolean;
};

export const PageTitle: FC<Props> = (props) => {
  const { center = false, children, dense = false } = props;

  return (
    <StyledPageTitle isCentered={center} isDense={dense}>
      {children}
    </StyledPageTitle>
  );
};
