import { Link } from 'react-router-dom';
import type { CSSProperties } from 'styled-components';
import styled from 'styled-components';

import { palette } from '../../consts';

type ButtonProps = {
  disabled?: boolean;
};

const commonProps = (props: ButtonProps): CSSProperties => ({
  backgroundColor: palette.biscay,
  border: 'unset',
  borderRadius: 8,
  color: palette.white,
  cursor: 'pointer',
  display: 'inline-block',
  fontSize: '1.125rem',
  lineHeight: '2rem',
  margin: 'auto',
  padding: '8px 24px',
  ...(props.disabled && { cursor: 'default', opacity: 0.8 }),
});

export const ButtonRow = styled('div')({
  display: 'flex',
});

export const StyledButton = styled('button')<ButtonProps>((props) => ({
  '&:hover': {
    opacity: 0.8,
    textDecoration: 'none',
  },
  ...commonProps(props),
}));

export const StyledLink = styled(Link)<ButtonProps>((props) => ({
  '&:hover': {
    opacity: 0.8,
    textDecoration: 'none',
  },
  ...commonProps(props),
}));
