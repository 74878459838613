import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { device, palette, theme } from '../../consts';

type MenuProps = { open: boolean };

export const StyledMenu = styled('ul')<MenuProps>`
  list-style: none;

  @media ${device.mobile} {
    background: ${palette.biscay};
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    right: 0;
    padding: 2rem;
    position: fixed;
    top: 0;
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
    transition: transform 0.3s ease-in-out;
    width: 100%;
    z-index: 10;
  }
`;

export const StyledNavLink = styled(NavLink)`
  & + & {
    margin-left: 60px;
  }

  &:hover {
    border-color: ${palette.mandysPink};
    text-decoration: none;
  }

  border-bottom: 3px solid transparent;
  color: ${palette.white};
  font-family: ${theme.fontFamily};
  font-size: 1.25rem;
  line-height: 1.625rem;
  padding-bottom: 8px;

  @media ${device.mobile} {
    color: ${palette.white};
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 0.5rem;
    padding: 2rem 0;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: color 0.3s linear;

    & + & {
      margin-left: 0;
    }
  }
}
`;
