import styled from 'styled-components';

import { palette } from '../../consts';

type StyledPageTitleProps = { isCentered: boolean; isDense: boolean };

export const StyledPageTitle = styled('h2')<StyledPageTitleProps>((props) => ({
  color: palette.biscay,
  fontSize: '2rem',
  fontWeight: 'bold',
  lineHeight: '2.5rem',
  margin: 0,
  marginBottom: props.isDense ? 16 : 32,
  textAlign: props.isCentered ? 'center' : 'unset',
}));
