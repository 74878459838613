import styled from 'styled-components';

import { palette } from '../../consts';

export const Container = styled('ul')({
  display: 'flex',
  flexWrap: 'wrap',
  left: -12,
  listStyle: 'none',
  marginTop: 28,
  position: 'relative',
  right: -12,
});

export const Feature = styled('li')({
  '& > svg': {
    color: palette.biscay,
    marginRight: 8,
  },
  alignItems: 'center',
  display: 'flex',
  marginBottom: 4,
  marginLeft: 12,
  marginRight: 12,
  marginTop: 4,
});

export const Text = styled('p')({
  fontSize: '1.125rem',
  lineHeight: '2rem',
  margin: 0,
});
