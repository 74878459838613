import type { FC, ReactNode } from 'react';
import React, { useCallback, useEffect, useMemo } from 'react';
import type { UseTranslationOptions } from 'react-i18next';
import { useTranslation } from 'react-i18next';

import { getTranslations$, QUERY_KEY, useQuery } from '../http';
import { Context } from './context';
import { initTranslations } from './service';

type Props = {
  children: NonNullable<ReactNode>;
};

export const Provider: FC<Props> = (props) => {
  const { children } = props;
  const options: UseTranslationOptions = { useSuspense: false };
  const { t, i18n, ready } = useTranslation(undefined, options);
  const langCode = useMemo(
    () => (i18n.languages?.[0] || 'en') as GenericType.Language,
    [i18n.languages],
  );
  const { data: translations } = useQuery(
    QUERY_KEY.Translations,
    getTranslations$,
  );
  const changeLanguage = useCallback(async () => {
    if (!ready) return;

    const language = langCode === 'en' ? 'pl' : 'en';

    await i18n.changeLanguage(language);
  }, [i18n, langCode, ready]);
  const contextValue = useMemo(
    () => ({ changeLanguage, langCode, t }),
    [changeLanguage, langCode, t],
  );

  useEffect(() => {
    if (!translations) return;

    initTranslations(translations);
  }, [translations]);

  return (
    <Context.Provider value={contextValue}>
      {ready ? children : null}
    </Context.Provider>
  );
};
