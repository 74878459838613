import styled from 'styled-components';

import { device, palette } from '../../consts';
import { StyledSection } from '../Section/section.styles';

const BCG_PATH = '/assets/bcg.svg';

export const TopSection = styled('div')`
  background-color: ${palette.biscay};
  background-image: url(${BCG_PATH});
  background-position: calc(100% + 100px) calc(100% + 2px);
  background-repeat: no-repeat;
  margin-bottom: auto;

  @media (max-width: 1000px) {
    background-position-x: calc(100% + 400px);
  }

  @media ${device.mobile} {
    display: none;
  }
`;

export const ExtendedSection = styled(StyledSection)`
  padding-bottom: 16px;
  padding-top: 16px;
`;

export const TopContent = styled('div')({
  maxWidth: 600,
  minHeight: 280,
  padding: '56px 48px',
  width: '100%',
});

export const TopTitle = styled('h1')((props) => ({
  color: props.color || palette.white,
  fontSize: '2.5rem',
  fontWeight: 'bold',
  lineHeight: '3.25rem',
  marginBottom: 16,
}));

export const Subtitle = styled('p')((props) => ({
  color: props.color || palette.white,
  fontSize: '1.125rem',
  lineHeight: '1.5rem',
  marginBottom: 16,
}));

export const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: 400,
});
