import type { FC } from 'react';
import React from 'react';

import type { Room } from '../../http';
import { Description } from '../Description/description.component';
import { Features } from '../Features/features.component';
import { Subtitle } from '../Subtitle/subtitle.component';
import { Container } from './rooms.styles';

type Props = {
  rooms: Room[];
};

export const Rooms: FC<Props> = (props) => {
  const { rooms } = props;

  if (!rooms || rooms.length === 0) return null;

  return (
    <>
      {rooms.map((room) => {
        const { description, features, key, title } = room;

        return (
          <Container key={key}>
            {title ? <Subtitle>{title}</Subtitle> : null}
            {description ? <Description value={description} /> : null}
            <Features features={features} />
          </Container>
        );
      })}
    </>
  );
};
