import type { FC } from 'react';
import React from 'react';

import { useTranslation } from '../../translations';
import { ExtendedSection, StyledFooter } from './footer.styles';

export const Footer: FC = () => {
  const { t } = useTranslation();
  const date = new Date();
  const text = t('footer.copyright');

  return (
    <StyledFooter>
      <ExtendedSection>
        <>
          Copyright &copy; {date.getFullYear()} CityFunApartments. {text}.
        </>
      </ExtendedSection>
    </StyledFooter>
  );
};
