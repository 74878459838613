import sanityClient from '@sanity/client';

import type { PageSlug } from './page.enum';
import type { Apartment, PageData, SiteSettings, Translation } from './type';

const PROJECT_ID = process.env.REACT_APP_CMS_PROJECT_ID || '';
const DATASET = process.env.REACT_APP_CMS_DATASET || '';

const client = sanityClient({
  apiVersion: '2021-08-31',
  dataset: DATASET,
  projectId: PROJECT_ID,
  useCdn: process.env.NODE_ENV === 'production',
});

const fetch$ = <T>(
  query: string,
  params: Record<string, unknown> = {},
): Promise<T> => client.fetch<T>(query.replace(/\s/g, ''), params);

export const getApartments$ = (
  language: GenericType.Language,
): Promise<Apartment[]> => {
  const query = `
    *[_type == $type]{
      "id": _id, 
      "features": features[]{
        "key": _key,
        "type": @->type,
        "value": @->value[$language]
      },
      name, 
      "images": images[].asset->url,
    }`;

  return fetch$(query, { language, type: 'apartment' });
};

export const getApartment$ = (
  id: Apartment['id'],
  language: GenericType.Language,
): Promise<Apartment> => {
  const query = `
    *[_type == $type && _id == $id]{
      "description": description[$language], 
      "features": features[]{
        "key": _key,
        "type": @->type,
        "value": @->value[$language]
      },
      "id": _id, 
      "images": images[].asset->url, 
      name,
      "rooms": rooms[]{
        "key": _key,
        "features": features[]{ 
          "key": _key,
          "type": @->type,
          "value": @->value[$language]
        },
        "description": description[$language],
        "title": title[$language],
      },
      seo{
        "title": title[$language],
        "description": description[$language],
        "keywords": keywords[][$language]
      },
    }[0]
  `;

  return fetch$(query, { id, language, type: 'apartment' });
};

export const getPage$ = (
  slug: PageSlug,
  language: GenericType.Language,
): Promise<PageData> => {
  const query = `
    *[_type == $type && slug.current == $slug]{
      "description": description[$language],
      "features": features[]{
        "key": _key,
        "type": @->type,
        "value": @->value[$language]
       },
      "name": name[$language],
      seo{
        "title": title[$language],
        "description": description[$language],
        "keywords": keywords[][$language]
      },
      "subtitle": subtitle[$language],
      "title": title[$language],
    }[0]
  `;

  return fetch$(query, { language, slug, type: 'page' });
};

export const getApartmentImages$ = (): Promise<string[]> => {
  const query = `*[_type == $type].images[].asset->url`;

  return fetch$(query, { type: 'apartment' });
};

export const getTranslations$ = (): Promise<Translation[]> => {
  const query = `*[_type == $type]{ key, value }`;

  return fetch$(query, { type: 'translation' });
};

export const getMenu$ = (
  language: GenericType.Language,
): Promise<SiteSettings['menu']> => {
  const query = `
    *[_type == $type][0]
      .menu[]{
        "key": _key,
        "slug": @->slug.current, 
        "title": @->name[$language], 
      }
  `;

  return fetch$(query, { language, type: 'siteSettings' });
};

export const getContactInfo$ = (): Promise<SiteSettings['contact']> => {
  const query = `*[_type == $type][0].contact`;

  return fetch$(query, { type: 'siteSettings' });
};
