import styled from 'styled-components';

import { device, palette } from '../../consts';
import { StyledSection } from '../Section/section.styles';

export const StyledHeader = styled('header')`
  background-color: ${palette.biscay};
`;

export const ExtendedSection = styled(StyledSection)`
  align-items: center;
  display: flex;
  justify-content: space-between;

  @media ${device.mobile} {
    align-items: unset;
  }
`;

export const LogoContainer = styled('div')`
  display: flex;
  justify-content: space-between;

  @media ${device.mobile} {
    justify-content: space-between;
    margin-bottom: 24px;
  }
`;
