import styled from 'styled-components';

const BCG_PATH = '/assets/bcg_bottom.svg';

export const StyledLayout = styled('main')({
  backgroundImage: `url(${BCG_PATH})`,
  backgroundPosition: 'right calc(100% - 59px)',
  backgroundRepeat: 'no-repeat',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'flex-start',
  minHeight: '100vh',
});
