import type { PortableTextProps } from '@portabletext/react/src/types';
import type { Key } from 'react';

import type { PageSlug } from './page.enum';

export enum FeatureType {
  Balcony = 'balcony',
  Cosmetics = 'cosmetics',
  CutleryPlate = 'cutlery',
  Dishwasher = 'dishwasher',
  DoubleBed = 'doubleBed',
  Dryer = 'dryer',
  Fridge = 'fridge',
  GLass = 'glass',
  HairDryer = 'hairDryer',
  Iron = 'iron',
  Kettle = 'kettle',
  Location = 'location',
  Other = 'other',
  Oven = 'oven',
  Pets = 'pets',
  SmokeDetector = 'smokeDetector',
  Shower = 'shower',
  Sink = 'sink',
  Size = 'size',
  Sofa = 'sofa',
  TV = 'tv',
  Towel = 'towel',
  Wardrobe = 'wardrobe',
  WashingMachine = 'washingMachine',
  WiFi = 'wifi',
  WineGlass = 'wineGlass',
}

export type Feature = {
  key: Key;
  type: FeatureType;
  value: string;
};

export type Room = {
  features: Feature[];
  key: Key;
  description?: PortableTextProps['value'];
  title?: string;
};

export type Apartment = {
  description: PortableTextProps['value'];
  features: Feature[];
  id: string;
  images: string[];
  name: string;
  rooms: Room[];
  seo?: Seo;
};

export type PageData = {
  description: PortableTextProps['value'];
  name: string;
  subtitle: string;
  title: string;
  features?: Feature[];
  seo?: Seo;
};

export type Translation = {
  key: GenericType.TranslationKey;
  value: Record<GenericType.Language, string>;
};

export type MenuItem = {
  key: Key;
  slug: PageSlug;
  title: string;
};

export type SiteSettings = {
  contact: {
    email: string;
    phone: string;
    address?: string;
  };
  menu: MenuItem[];
  siteName: string;
};

export type Seo = {
  description?: string;
  keywords?: string[];
  title?: string;
};
