import styled from 'styled-components';

import { palette } from '../../consts';
import { StyledSection } from '../Section/section.styles';

export const StyledFooter = styled('footer')({
  backgroundColor: palette.biscay,
  marginTop: 'auto',
});

export const ExtendedSection = styled(StyledSection)({
  color: palette.white,
  fontSize: 11,
  marginBottom: 0,
  marginTop: 0,
  textAlign: 'right',
});
