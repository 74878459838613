import type { FC, ReactNode } from 'react';
import React from 'react';
import { Helmet } from 'react-helmet';

import type { Seo } from '../../http';
import { useTranslation } from '../../translations';
import { Contact } from '../Contact/contact.component';
import { Spinner } from '../Spinner/spinner.component';
import {
  Content,
  ExtendedSection,
  Subtitle,
  TopContent,
  TopSection,
  TopTitle,
} from './page.styles';

type Props = {
  children: NonNullable<ReactNode>;
  seo?: Seo;
  subtitle?: string;
  isFetching?: boolean;
  titleKey?: GenericType.TranslationKey;
  title?: string;
};

export const Page: FC<Props> = (props) => {
  const { children, isFetching, seo, subtitle, title, titleKey } = props;
  const { t } = useTranslation();
  const titleValue = titleKey ? t(titleKey) : title || '';
  const seoTitle = seo?.title || titleValue;
  const keywords = (seo?.keywords || []).filter((keyword) => keyword).join(' ');

  return (
    <>
      {titleValue || seo?.description ? (
        <Helmet>
          {seoTitle ? (
            <title>
              {seoTitle}
              {subtitle ? ` - ${subtitle}` : ''}
            </title>
          ) : null}
          {seo?.description ? (
            <meta name="description" content={seo.description} />
          ) : null}
          {keywords ? <meta name="keywords" content={keywords} /> : null}
        </Helmet>
      ) : null}
      <TopSection>
        <ExtendedSection>
          <TopContent>
            <TopTitle>{titleValue}</TopTitle>
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
          </TopContent>
        </ExtendedSection>
      </TopSection>
      <Content>
        {isFetching ? (
          <Spinner />
        ) : (
          <>
            {children}
            <Contact />
          </>
        )}
      </Content>
    </>
  );
};
