import type {
  Dispatch,
  FC,
  KeyboardEvent,
  MouseEvent,
  SetStateAction,
} from 'react';
import React, { useCallback, useEffect, useRef } from 'react';

import { LeftIcon, RightIcon } from '../../../icons';
import { DEFAULT_IMAGE_IDX } from '../gallery.const';
import type { Direction } from '../gallery.type';
import {
  ArrowLeft,
  ArrowRight,
  StyledThumbnails,
  Thumbnail,
} from './thumbnails.styles';

type Props = {
  activeImageIdx: number;
  images: string[];
  isFullPreview: boolean;
  setActiveImageIdx: Dispatch<SetStateAction<number>>;
  setFullPreview: Dispatch<boolean>;
};

export const Thumbnails: FC<Props> = (props) => {
  const {
    activeImageIdx,
    images,
    isFullPreview,
    setActiveImageIdx,
    setFullPreview,
  } = props;
  const ref = useRef<HTMLDivElement>(null);
  const thumbnailRefs = useRef<HTMLDivElement[]>([]);
  const scrollTo = useCallback((idx: number) => {
    thumbnailRefs.current[idx].scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  }, []);
  const handleClick = useCallback(
    (event: MouseEvent<HTMLDivElement>, idx: number) => {
      event.stopPropagation();
      setActiveImageIdx(idx);
      scrollTo(idx);
    },
    [scrollTo, setActiveImageIdx],
  );
  const changeVisibleImage = useCallback(
    (direction: Direction, event?: MouseEvent<HTMLDivElement>) => {
      setActiveImageIdx((prevState) => {
        event?.stopPropagation();
        const getIdx = (): number => {
          if (direction === 'left' && prevState === DEFAULT_IMAGE_IDX)
            return images.length - 1;
          if (direction === 'right' && prevState === images.length - 1)
            return 0;

          return direction === 'left' ? prevState - 1 : prevState + 1;
        };

        const idx = getIdx();

        scrollTo(idx);

        return idx;
      });
    },
    [images.length, scrollTo, setActiveImageIdx],
  );
  const handleKeyDown = useCallback(
    (ev: KeyboardEvent<HTMLDivElement>): void => {
      switch (ev.key) {
        case 'ArrowLeft': {
          changeVisibleImage('left');
          break;
        }
        case 'ArrowRight': {
          changeVisibleImage('right');
          break;
        }
        case 'Escape': {
          setFullPreview(false);
          break;
        }
        default:
      }
    },
    [changeVisibleImage, setFullPreview],
  );

  useEffect(() => {
    if (!isFullPreview) return;

    ref.current?.focus();
    scrollTo(activeImageIdx);
  }, [activeImageIdx, isFullPreview, scrollTo]);

  return (
    <>
      <StyledThumbnails
        ref={ref}
        aria-hidden="true"
        onKeyDown={handleKeyDown}
        tabIndex={0}
      >
        {images.map((image, idx) => (
          <Thumbnail
            active={idx === activeImageIdx}
            key={image}
            onClick={(event) => handleClick(event, idx)}
            ref={(ref: HTMLDivElement) => {
              thumbnailRefs.current[idx] = ref;
            }}
            url={image}
          />
        ))}
      </StyledThumbnails>
      {!isFullPreview && (
        <>
          <ArrowLeft onClick={(event) => changeVisibleImage('left', event)}>
            <LeftIcon height={24} width={24} />
          </ArrowLeft>
          <ArrowRight onClick={(event) => changeVisibleImage('right', event)}>
            <RightIcon height={24} width={24} />
          </ArrowRight>
        </>
      )}
    </>
  );
};
