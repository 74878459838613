import type { FC, MouseEvent } from 'react';
import React, { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import type { MenuItem } from '../../http';
import { PageSlug } from '../../http';
import { StyledMenu, StyledNavLink } from './menu.styles';

type Props = {
  handleClose: () => void;
  isOpen: boolean;
  items: MenuItem[];
};

export const Menu: FC<Props> = (props) => {
  const { handleClose, isOpen, items } = props;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const scrollTo = useCallback(
    (event: MouseEvent<HTMLAnchorElement>, url: string) => {
      event.preventDefault();
      handleClose();

      if (pathname !== PageSlug.Home && url === PageSlug.Apartments) {
        const slug = `${PageSlug.Home}${url}`;

        navigate(slug);

        return;
      }

      const id = url.replace('#', '');
      const block = document.getElementById(id);

      if (!block) return;

      block.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });
    },
    [handleClose, navigate, pathname],
  );

  return (
    <StyledMenu open={isOpen}>
      {items.map((item) => {
        const { key, slug, title } = item;
        const isSlugToElement = slug.includes('#');

        return (
          <StyledNavLink
            key={key}
            {...(isSlugToElement
              ? { onClick: (e) => scrollTo(e, slug), to: '' }
              : { onClick: handleClose, to: slug })}
          >
            {title}
          </StyledNavLink>
        );
      })}
    </StyledMenu>
  );
};
