import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import type { Translation } from '../http';

export const DEFAULT_CONTEXT = 'translation';

export const initTranslations = async (
  translations: Translation[],
): Promise<void> => {
  const resources = translations.reduce(
    (translations, translation) => ({
      ...translations,
      en: {
        [DEFAULT_CONTEXT]: {
          ...translations.en[DEFAULT_CONTEXT],
          [translation.key]: translation.value.en,
        },
      },
      pl: {
        [DEFAULT_CONTEXT]: {
          ...translations.pl[DEFAULT_CONTEXT],
          [translation.key]: translation.value.pl,
        },
      },
    }),
    { en: { [DEFAULT_CONTEXT]: {} }, pl: { [DEFAULT_CONTEXT]: {} } },
  );

  await i18n
    // .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      defaultNS: DEFAULT_CONTEXT,
      fallbackLng: 'pl',
      interpolation: { escapeValue: false },
      load: 'languageOnly',
      resources,
    });
};
