import type { Dispatch, FC, SetStateAction } from 'react';
import React from 'react';

import { Thumbnails } from '../Thumbnails/thumbnails.component';
import { StyledFullPreview, Container, Image } from './full-preview.styles';

type Props = {
  activeImageIdx: number;
  activeImageUrl: string;
  images: string[];
  isOpen: boolean;
  setActiveImageIdx: Dispatch<SetStateAction<number>>;
  setOpen: Dispatch<boolean>;
};

export const FullPreview: FC<Props> = (props) => {
  const {
    activeImageIdx,
    activeImageUrl,
    images,
    isOpen,
    setActiveImageIdx,
    setOpen,
  } = props;

  return (
    <StyledFullPreview onClick={() => setOpen(false)}>
      <Container>
        <Image url={activeImageUrl} />
        <Thumbnails
          activeImageIdx={activeImageIdx}
          images={images}
          isFullPreview={isOpen}
          setActiveImageIdx={setActiveImageIdx}
          setFullPreview={setOpen}
        />
      </Container>
    </StyledFullPreview>
  );
};
