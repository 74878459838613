import styled from 'styled-components';

import { device } from '../../consts';

export const Container = styled('div')({
  float: 'left',
  marginRight: 24,
  maxWidth: '33%',
  position: 'relative',
  width: '100%',

  [`@media ${device.mobile}`]: {
    float: 'unset',
    maxWidth: '100%',
  },
});

export const Preview = styled('div')<{ url: string }>((props) => ({
  backgroundImage: `url(${props.url})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  borderRadius: '8px',
  cursor: 'pointer',
  height: 450,
  width: '100%',
}));
