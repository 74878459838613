import type { TFunction, TFunctionKeys } from 'i18next';
import { createContext } from 'react';

export type ContextValue = {
  changeLanguage: () => Promise<void>;
  langCode: GenericType.Language;
  t: TFunction;
};

export const Context = createContext<ContextValue>({
  changeLanguage: () => Promise.resolve(),
  langCode: 'en',
  t: (key: TFunctionKeys) => key,
});
