import { PortableText } from '@portabletext/react';
import type { PortableTextProps } from '@portabletext/react/src/types';
import type { FC, ReactElement } from 'react';
import React from 'react';

import {
  StyledListItem,
  StyledOrderedList,
  StyledOrderedListItem,
  StyledParagraph,
  StyledUnorderedList,
} from './description.styles';

type Props = {
  value: PortableTextProps['value'];
};

const components: PortableTextProps['components'] = {
  block: {
    normal: ({ children }): ReactElement => (
      <StyledParagraph>{children}</StyledParagraph>
    ),
  },
  list: {
    bullet: ({ children }): ReactElement => (
      <StyledUnorderedList>{children}</StyledUnorderedList>
    ),
    number: ({ children }): ReactElement => (
      <StyledOrderedList>{children}</StyledOrderedList>
    ),
  },
  listItem: {
    bullet: ({ children }): ReactElement => (
      <StyledListItem>{children}</StyledListItem>
    ),
    number: ({ children }): ReactElement => (
      <StyledOrderedListItem>{children}</StyledOrderedListItem>
    ),
  },
};

export const Description: FC<Props> = (props) => {
  const { value } = props;

  return <PortableText components={components} value={value} />;
};
