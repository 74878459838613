import type { FC } from 'react';
import React, { Suspense } from 'react';

import { Loading } from '../Loading/loading.component';

export const Loadable: (Component: FC) => FC = (Component) => (props) =>
  (
    <Suspense fallback={<Loading />}>
      <Component {...props} />
    </Suspense>
  );
