import type { CSSProperties } from 'styled-components';
import styled from 'styled-components';

import { device } from '../../consts';

export type GridProps = {
  alignItems?: CSSProperties['alignItems'];
  columns?: number;
  container?: boolean;
  flexDirection?: CSSProperties['flexDirection'];
  justifyContent?: CSSProperties['justifyContent'];
};

export const Container = styled('div')<GridProps>`
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  flex-wrap: wrap;
  justify-content: ${(props) => props.justifyContent};
  margin: -16px;
`;

export const Item = styled('div')<GridProps>`
  align-items: ${(props) => props.alignItems};
  flex-direction: ${(props) => props.flexDirection};
  justify-content: ${(props) => props.justifyContent};
  padding: 16px;
  width: calc(100% / ${(props) => props.columns || 2});

  @media ${device.tablet} {
    width: 50%;
  }

  @media ${device.mobile} {
    width: 100%;
  }
`;
