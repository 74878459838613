import styled from 'styled-components';

import { palette } from '../../consts';

export const Box = styled('div')({
  backgroundColor: palette.white,
  borderRadius: 8,
  boxShadow: '0px 4px 25px rgba(0, 0, 0, 0.25)',
  padding: '16px 16px 32px',
  width: '100%',
});

export const ImageContainer = styled('div')({
  marginBottom: 16,
  overflow: 'hidden',
});

export const Image = styled('div')<{ url: string }>((props) => ({
  '&:hover': {
    transform: 'scale(1.3)',
  },
  backgroundImage: `url(${props.url})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  height: 242,
  transform: 'scale(1)',
  transition: '.3s ease-in-out',
}));

export const Title = styled('h3')({
  color: palette.biscay,
  fontSize: '1.5rem',
  fontWeight: 'bold',
  lineHeight: '1.875rem',
  margin: 0,
});
