import type { FC } from 'react';
import React from 'react';

import { StyledSubtitle } from './subtitle.styles';

type Props = {
  children: string;
};

export const Subtitle: FC<Props> = (props) => {
  const { children } = props;

  return <StyledSubtitle>{children}</StyledSubtitle>;
};
