import styled from 'styled-components';

import { palette } from '../../consts';

export const StyledSubtitle = styled('h3')({
  color: palette.biscay,
  fontSize: '1.5rem',
  fontWeight: 'bold',
  lineHeight: '2rem',
  margin: 0,
  marginBottom: 16,
});
