import type { FC } from 'react';
import React, { useCallback, useEffect, useState } from 'react';

import { size } from '../../consts';
import { useWindowWide } from '../../hooks';
import { getMenu$, QUERY_KEY, useQuery } from '../../http';
import { useTranslation } from '../../translations';
import { Burger } from '../Burger/burger.component';
import { Menu } from '../Menu/menu.component';
import { StyledNav } from './navigation.styles';

export const Navigation: FC = () => {
  const { langCode } = useTranslation();
  const isWide = useWindowWide(size.mobile);
  const getData$ = useCallback(() => getMenu$(langCode), [langCode]);
  const { data } = useQuery([QUERY_KEY.Menu, langCode], getData$);
  const [isOpen, setOpen] = useState(false);
  const handleToggle = (): void => setOpen((prevState) => !prevState);
  const handleClose = (): void => setOpen(false);

  useEffect(() => {
    const html = document.querySelector('html');

    if (!html) return;

    html.style.overflowY = isOpen ? 'hidden' : 'auto';
  }, [isOpen]);

  useEffect(() => {
    if (!isWide) return;

    const html = document.querySelector('html');

    if (!html) return;

    html.style.overflowY = 'auto';
  }, [isWide]);

  if (!data) return null;

  return (
    <StyledNav>
      <Burger isOpen={isOpen} onClick={handleToggle} />
      <Menu handleClose={handleClose} isOpen={isOpen} items={data} />
    </StyledNav>
  );
};
