import type { FC, ReactNode } from 'react';
import React from 'react';

import { StyledSection } from './section.styles';

type Props = {
  children: NonNullable<ReactNode>;
  dense?: boolean;
  id?: string;
};

export const Section: FC<Props> = (props) => {
  const { children, dense = false, id } = props;

  return (
    <StyledSection id={id} isDense={dense}>
      {children}
    </StyledSection>
  );
};
