import type { FC } from 'react';
import React from 'react';

import { IconLeft, IconRight, StyledButton } from './burger.styles';

type Props = {
  isOpen: boolean;
  onClick: () => void;
};

export const Burger: FC<Props> = (props) => {
  const { isOpen, onClick } = props;

  return (
    <StyledButton onClick={onClick}>
      <IconLeft open={isOpen} />
      <IconRight open={isOpen} />
    </StyledButton>
  );
};
