export enum QUERY_KEY {
  Apartment = 'apartment',
  ApartmentImages = 'apartment_images',
  Apartments = 'apartments',
  ApartmentsPage = 'apartments_page',
  ContactInfo = 'contact_info',
  ContactPage = 'contact_page',
  HomePage = 'home_page',
  Menu = 'menu',
  Settings = 'settings',
  Translations = 'translations',
}
