import type { CSSObject } from 'styled-components';
import styled from 'styled-components';

import { device, palette } from '../../consts';

type FieldProps = { isError: boolean };

export const Form = styled('form')`
  max-width: 500px;
  width: 100%;

  @media ${device.mobile} {
    max-width: unset;
  }
`;

export const Field = styled('div')({
  marginBottom: 16,
});

const inputProps = (props: FieldProps): CSSObject => ({
  '&:disabled': {
    opacity: 0.8,
  },
  border: `1px solid ${
    props.isError ? palette.cinnabar : palette.silverChalice
  }`,
  borderRadius: 8,
  color: palette.mineShaft,
  display: 'block',
  fontSize: '1rem',
  lineHeight: '1.5rem',
  padding: '8px 16px',
  width: '100%',
});

export const Input = styled('input')<FieldProps>((props) => ({
  '&:focus, &:hover': {
    borderColor: props.isError ? palette.cinnabar : palette.biscay,
  },
  ...inputProps(props),
}));

export const Textarea = styled('textarea')<FieldProps>((props) => ({
  '&:focus, &:hover': {
    borderColor: props.isError ? palette.cinnabar : palette.biscay,
  },
  ...inputProps(props),
}));

export const Error = styled('div')({
  color: palette.cinnabar,
  fontSize: '0.725rem',
  marginTop: 4,
});
