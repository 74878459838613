import type { FC } from 'react';
import React from 'react';

import type { Feature as TFeature } from '../../http';
import { getFeatureTypeIcon, getValue } from './features.helper';
import { Container, Feature, Text } from './features.styles';

type Props = {
  features: TFeature[];
  size?: 'large' | 'normal';
};

export const Features: FC<Props> = (props) => {
  const { features, size = 'normal' } = props;

  if (features.length === 0) return null;

  return (
    <Container>
      {features.map((feature) => {
        const { key, type, value } = feature;
        const Icon = getFeatureTypeIcon(type);
        const text = getValue(type, value);

        return (
          <Feature key={key}>
            <Icon
              height={size === 'large' ? 48 : 24}
              width={size === 'large' ? 48 : 24}
            />
            <Text>{text}</Text>
          </Feature>
        );
      })}
    </Container>
  );
};
