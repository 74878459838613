import styled from 'styled-components';

import { palette } from '../../../consts';

export const StyledThumbnails = styled('div')({
  display: 'flex',
  flexWrap: 'nowrap',
  marginTop: 12,
  overflowX: 'auto',
  position: 'relative',
});

export const Thumbnail = styled('div')<{ active: boolean; url: string }>(
  (props) => ({
    '& + &': {
      marginLeft: 12,
    },
    backgroundImage: `url(${props.url})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    border: '3px solid transparent',
    borderRadius: '8px',
    cursor: 'pointer',
    flex: '0 0 200px',
    height: 140,
    ...(props.active && { borderColor: palette.biscay }),
  }),
);

const Arrow = styled('div')({
  '&:hover': {
    opacity: 0.8,
  },
  backdropFilter: 'blur(25px)',
  backgroundColor: 'rgba(255, 255, 255, 0.5);',
  borderRadius: '50%',
  bottom: 54,
  cursor: 'pointer',
  height: 32,
  padding: 4,
  position: 'absolute',
  width: 32,
});

export const ArrowLeft = styled(Arrow)({
  left: 0,
});

export const ArrowRight = styled(Arrow)({
  right: 0,
});
