import type { FC } from 'react';
import React, { useMemo, useState } from 'react';

import type { Apartment } from '../../http';
import { FullPreview } from './FullPreview/full-preview.component';
import { Thumbnails } from './Thumbnails/thumbnails.component';
import { DEFAULT_IMAGE_IDX } from './gallery.const';
import { Container, Preview } from './gallery.styles';

type Props = {
  images: Apartment['images'];
};

export const Gallery: FC<Props> = (props) => {
  const { images } = props;
  const [activeImageIdx, setActiveImageIdx] = useState(DEFAULT_IMAGE_IDX);
  const [isFullPreview, setFullPreview] = useState(false);
  const activeImageUrl = useMemo(
    () => images[activeImageIdx],
    [activeImageIdx, images],
  );

  return (
    <>
      <Container>
        <Preview onClick={() => setFullPreview(true)} url={activeImageUrl} />
        <Thumbnails
          activeImageIdx={activeImageIdx}
          images={images}
          isFullPreview={isFullPreview}
          setActiveImageIdx={setActiveImageIdx}
          setFullPreview={setFullPreview}
        />
      </Container>
      {isFullPreview && (
        <FullPreview
          activeImageIdx={activeImageIdx}
          activeImageUrl={activeImageUrl}
          images={images}
          isOpen={isFullPreview}
          setActiveImageIdx={setActiveImageIdx}
          setOpen={setFullPreview}
        />
      )}
    </>
  );
};
