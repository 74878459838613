import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { palette, theme } from '../../consts';

export const StyledLogo = styled(Link)`
  color: ${palette.white};
  display: block;
  font-family: ${theme.fontFamily};
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.625rem;

  &:hover {
    text-decoration: none;
  }
`;
