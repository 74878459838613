export const palette = {
  biscay: '#183C65',
  cinnabar: '#E74C3C',
  ebb: '#EEE6E6',
  mandysPink: '#F7CABF',
  mineShaft: '#3D3D3D',
  silver: '#C4C4C4',
  silverChalice: '#B1B1B1',
  white: '#FFFFFF',
};
