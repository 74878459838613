import styled from 'styled-components';

import { device } from '../../consts';

export const StyledParagraph = styled('p')`
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 2rem;
  margin: 0 0 16px;
  text-align: justify;

  & + ul,
  & + ol {
    margin-top: -8px;
  }

  @media ${device.mobile} {
    max-width: unset;
  }
`;

export const StyledUnorderedList = styled('ul')`
  padding-left: 16px;
  margin-bottom: 16px;
`;

export const StyledOrderedList = styled('ol')`
  padding-left: 0;
  margin-bottom: 16px;
  counter-reset: item;

  ol {
    padding-left: 16px;
  }
`;

export const StyledListItem = styled('li')`
  font-size: 1.125rem;

  & + & {
    margin-top: 8px;
  }

  ul,
  ol {
    margin-bottom: 0;
  }
`;

export const StyledOrderedListItem = styled(StyledListItem)`
  display: block;

  &:before {
    content: counters(item, '.') ' ';
    counter-increment: item;
  }
`;
