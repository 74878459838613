import type { FC, SVGProps } from 'react';

import type { Feature } from '../../http';
import { FeatureType } from '../../http';
import {
  AreaIcon,
  BalconyWindowIcon,
  CosmeticsIcon,
  CutleryPlateIcon,
  DishwasherIcon,
  DoubleBedIcon,
  DryerIcon,
  FridgeIcon,
  GlassIcon,
  HairDryerIcon,
  IronIcon,
  KettleIcon,
  OvenIcon,
  PetsIcon,
  PinIcon,
  ShowerIcon,
  SinkIcon,
  SmokeDetectorIcon,
  SofaIcon,
  StarIcon,
  TowelIcon,
  TVIcon,
  WardrobeIcon,
  WashingMachineIcon,
  WiFiIcon,
  WineGlassIcon,
} from '../../icons';

const featureTypeIconsMap: Record<FeatureType, FC<SVGProps<SVGSVGElement>>> = {
  [FeatureType.Balcony]: BalconyWindowIcon,
  [FeatureType.Cosmetics]: CosmeticsIcon,
  [FeatureType.CutleryPlate]: CutleryPlateIcon,
  [FeatureType.Dishwasher]: DishwasherIcon,
  [FeatureType.DoubleBed]: DoubleBedIcon,
  [FeatureType.Dryer]: DryerIcon,
  [FeatureType.Fridge]: FridgeIcon,
  [FeatureType.GLass]: GlassIcon,
  [FeatureType.HairDryer]: HairDryerIcon,
  [FeatureType.Kettle]: KettleIcon,
  [FeatureType.Location]: PinIcon,
  [FeatureType.Other]: StarIcon,
  [FeatureType.Oven]: OvenIcon,
  [FeatureType.Pets]: PetsIcon,
  [FeatureType.Shower]: ShowerIcon,
  [FeatureType.Sink]: SinkIcon,
  [FeatureType.Size]: AreaIcon,
  [FeatureType.Sofa]: SofaIcon,
  [FeatureType.Towel]: TowelIcon,
  [FeatureType.TV]: TVIcon,
  [FeatureType.Wardrobe]: WardrobeIcon,
  [FeatureType.WashingMachine]: WashingMachineIcon,
  [FeatureType.WiFi]: WiFiIcon,
  [FeatureType.WineGlass]: WineGlassIcon,
  [FeatureType.Iron]: IronIcon,
  [FeatureType.SmokeDetector]: SmokeDetectorIcon,
  [FeatureType.WineGlass]: WineGlassIcon,
};

export const getFeatureTypeIcon = (
  type: FeatureType,
): FC<SVGProps<SVGSVGElement>> => featureTypeIconsMap[type] || StarIcon;

export const getValue = (
  type: FeatureType,
  value: Feature['value'],
): string => {
  switch (type) {
    case FeatureType.Size:
      return `${value} m\u00B2`;
    default:
      return value;
  }
};
